import * as React from 'react';
import SEO from '../components/atoms/SEO';
import { Box, Typography } from '@material-ui/core';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function Cookies() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <SEO title="Cookie policy" />
      <Box>
        <Box p="6% 0% 1% 10%" width="100%" alignItems="left">
          <Typography variant="h4">Cookie policy</Typography>
          <p>
            <em>Last updated 27 April 2020</em>
          </p>
        </Box>
        <Box p="1% 10% 3% 10%" width="100%">
          <p>
            B2Rent Ltd (“we”, “our”, “us”) operates the buildtorent.io website
            (our “Site”).
          </p>
          <p>
            Our Site uses cookies to distinguish you from other users. This
            helps us to provide you with a good experience when you browse our
            Site and also allows us to improve our Site. By continuing to browse
            our Site, you are agreeing to our use of cookies.
          </p>
          <p>
            If you wish to remove cookies placed on your device by our Site or
            stop our Site placing further cookies on your device you can do this
            at any time (learn how to do this in the &#8220;Turning Cookies
            Off&#8221; section below), however doing so is likely to mean that
            our Site will not work as you would expect and certain functionality
            may be lost.
          </p>
          <p>
            A &#8220;cookie&#8221; is a piece of information, like a tag,
            that&#8217;s stored on your device when you visit a website. Cookies
            make web surfing easier for the user by saving user preferences
            while visiting a website. The only personal information a cookie can
            contain is information supplied by the user. A cookie cannot read
            data from a user&#8217;s hard drive or read cookie files created by
            other websites. The use of cookies is an industry standard that can
            be found on most major websites.
          </p>
          <p>
            We use information obtained from cookies to tailor our services
            better to our users&#8217; needs. Many improvements and updates to
            our Site are based on such data.
          </p>
          <p>
            We reserve the right to modify or amend the use of cookies at any
            time and for any reason. Any changes will be posted on our Site and
            will take effect as soon as they are posted. By continuing to use
            our Site after any changes are posted you are indicating your
            acceptance of those changes.
          </p>
          <p>
            <strong>
              <u>The cookies we use</u>
            </strong>
          </p>
          <p>We use the following cookies:</p>
          <p>
            <strong>
              <em>Strictly necessary cookies</em>:
            </strong>{' '}
            These are cookies that are required for the operation of our Site.
            They include, for example, cookies that enable you to log into
            secure areas of our Site.
          </p>
          <p>
            <strong>
              <em>Analytical/performance cookies</em>:
            </strong>{' '}
            These cookies allow us to recognise and count the number of visitors
            to our Site and to see how visitors move around our Site when they
            are using it. This helps us to improve the way our Site works, for
            example, by ensuring that users are finding what they are looking
            for easily. We use cookies to compile visitor statistics such as how
            many people have visited our Site, how they reached our Site, what
            type of technology they are using (e.g. Mac or Windows which helps
            to identify when our Site isn&#8217;t working as it should for
            particular technologies), how long they spend on our Site, what page
            they look at etc. This helps us to continuously improve our Site.
          </p>
          <p>
            <strong>
              <em>Functionality cookies</em>:
            </strong>{' '}
            These are used to recognise you when you return to our Site. This
            means that you do not need to log in each time you visit our Site
            and it enables us to personalise our content for you.
          </p>
          <p>
            <strong>
              <em>Targeting cookies</em>:
            </strong>{' '}
            These cookies record your visit to our Site or our affiliates’
            websites, the pages you have visited and the links you have
            followed. This information will be used by us and third parties to
            make our Site and the advertising displayed on it more relevant to
            your interests. We may also share this information with third
            parties for this purpose.
          </p>
          <p>
            You can find more information about the cookies we use and the
            purposes for which we use them below:
          </p>
          {/*@ts-ignore*/}
          <table border="1" cellSpacing="1" cellPadding="1">
            <thead>
              <tr>
                <th scope="col">Cookie</th>
                <th scope="col">Function</th>
                <th scope="col">Category and expiry</th>
                <th scope="col">More information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>_ga</td>
                <td>
                  Used to distinguish users and sessions. The cookie is created
                  when the javascript library executes and no existing __utma
                  cookies exists. The cookie is updated every time data is sent
                  to Google Analytics.
                </td>
                <td>2 years</td>
                <td>
                  <a href="/terms/privacy">Privacy policy</a>
                </td>
              </tr>
              <tr>
                <td>sess_id</td>
                <td>
                  Used to collate actions performed during a website visit into
                  a sequence of events.
                </td>
                <td>30 minutes</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>guid_id</td>
                <td>
                  Unique ID used to identify your browser when you visit
                  repeatedly either our Site or one of our affiliates’ websites.
                </td>
                <td>5 years</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>id &amp; ac</td>
                <td>
                  When logged in, these User IDs allows you to view Enquiries
                  and Bookings without having to login during each visit.
                </td>
                <td>30 days</td>
                <td>N/A</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>
              <u>Third-party cookies</u>
            </strong>
          </p>
          <p>
            Our Site may use third party cookies for a number of reasons, as set
            out below.
          </p>
          <p>
            Advertisements displayed on our Site may be delivered via an
            advertising management service. When you view a web page on our Site
            that contains advertising provided by one of our advertising
            management services, the advertising service company may place a
            cookie on or retrieve a cookie from your computer, if you have
            enabled cookies.
          </p>
          <p>
            Please note that third parties may also use cookies, over which we
            have no control. These cookies are likely to be
            analytical/performance cookies or targeting cookies. Third parties
            may place anonymous cookies on the browsers of visitors to their
            websites and may send their own cookies to the visitors’ cookie
            file.
          </p>
          <p>
            Our Site, like most websites, includes functionality provided by
            third parties. A common example is an embedded video powered by
            YouTube or Vimeo. Disabling these cookies may mean you cannot access
            the functions offered by these third parties.
          </p>
          <p>
            Our third party marketing partners (e.g. Google, Facebook and
            AdRoll) will use cookies to better target our paid channels.
          </p>
          <p>
            When you access our Site through social media, or we provide links
            to social media sites from our Site, you should be aware that the
            privacy implications vary from social media site to social media
            site and will be dependent on the privacy settings you have chosen
            on these sites. Please refer to the social media site&#8217;s
            privacy and cookies policy for more information.
          </p>
          <p>
            As a consequence of improving the user experience of the Site with
            third-party tools, third-party cookies from these services may be
            set while you visit the Site:
          </p>
          <ul>
            <li>Analytics (Google Analytics)</li>
            <li>Social Networks (e.g. Facebook, Twitter)</li>
            <li>
              Display Advertising (e.g. Google DoubleClick, FlashTalking, AdNxs,
              AdRoll)
            </li>
            <li>A/B Testing (Visual Website Optimiser)</li>
          </ul>
          <p>
            Note that some third party cookies change over time and location,
            for example, A/B tests and display advertising cookies vary based on
            the user’s location and other attributes. Third parties may also use
            cookies to track users across their devices, based on their Google
            account settings.
          </p>
          <p>
            <strong>
              <u>Turning Cookies Off</u>
            </strong>
          </p>
          <p>
            You can usually switch cookies off by adjusting your browser
            settings to stop it from accepting cookies. The Help function within
            your browser should tell you how to do this. For more information
            about how to change your browser settings please see:
            www.aboutcookies.org and www.youronlinechoices.com. Doing so however
            is likely to limit the functionality of our Site.
          </p>
        </Box>
      </Box>
    </GenericPage>
  );
}
